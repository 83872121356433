import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Sidebar from "../components/Sidebar"
import "./layout.css"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div className="layout">
        <main>{children}</main>
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#252525" }}
        buttonStyle={{
          background: "#107BF3",
          borderRadius: "4px",
          color: "#fff",
        }}
      >
        This website uses cookies to enhance the user experience. By using this
        site you agree to our use of cookies.{" "}
        <Link
          to="/privacy-policy"
          style={{ color: "#F0F0F0", paddingLeft: "10px" }}
        >
          Learn more here
        </Link>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
