import React from "react"
import {
  SidebarContainer,
  Button,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements"

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Button onClick={toggle} aria-label="Close">
        <CloseIcon />
      </Button>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Home
          </SidebarLink>
          <SidebarLink to="/posts" onClick={toggle}>
            Posts
          </SidebarLink>
          <SidebarLink to="/resources" onClick={toggle}>
            Resources
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
