import { Link } from "gatsby"
import styled from "styled-components"

export const FooterContainer = styled.div`
  background-color: #101522;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 16px auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
`

export const FooterLinks = styled.div`
  padding: 0.5rem calc((100vw - 1000px) / 2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const FooterLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 14px;
`
