import React from "react"
import { FaTwitter, FaYoutube } from "react-icons/fa"
import { AiFillGithub } from "react-icons/ai"
import {
  FooterContainer,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  FooterLinks,
  FooterLink,
} from "./FooterElements"
import Icon from "../../../static/logo.svg"

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              <Icon title="Home" />
            </SocialLogo>
            <WebsiteRights>
              CODAVILLA © {new Date().getFullYear()} All Rights Reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber"
                target="_blank"
                aria-label="Youtube"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="//www.twitter.com/briandesignz"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="//www.github.com/briancodex"
                target="_blank"
                aria-label="Github"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        <FooterLinks>
          <FooterLink to="/terms-and-conditions">
            Terms and Conditions
          </FooterLink>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink to="/disclaimer">Disclaimer</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
        </FooterLinks>
      </FooterContainer>
    </>
  )
}

export default Footer
